import React, { useState, ReactNode } from 'react';
import { NavBar } from '@/components/layouts/NavBar';
import SideBar from '@/components/layouts/SideBar';
import ErrorProvider from '@/providers/ErrorProvider';
import { cn } from '@/helpers/utils';
import Grid from '@mui/material/Grid';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InboxIcon from '@mui/icons-material/Inbox';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { HeadSetIcon } from "@/components/base/Icons/HeadSet";
import { useLanguage } from '@/hooks/useLanguage';
import { useLogout } from '@/hooks/useLogout';
import { useRouter }  from 'next/navigation';

type AppWrapperProps = {
  login?: boolean;
  className?: string;
  mainClass?: string;
  noFooter?: boolean;
  children: ReactNode;
}

export const AppWrapper = ({ login = false, className, mainClass = '', noFooter = false, children }: AppWrapperProps) => {
  const [value, setValue] = useState<number>(0);
  const { lang } = useLanguage()
  const { handleLogout } = useLogout()
  const router = useRouter()

  return (
    <main className={cn('appWrapper font-main overflow-x-hidden', mainClass)}>
      {!login && <NavBar />}
      <div className={cn(
        !login ? 'mainWrapper w-full md:w-[calc(100vw-57px)] md:ml-14 pl-4 pb-0 overflow-auto' : '',
        className || 'pt-4 pr-4'
      )}>
        <Grid container>
          <Grid item xs={12} className="grow">
            {!login && <SideBar />}
            <ErrorProvider>
              {children}
            </ErrorProvider>
            {(!login && !noFooter) && (
              <footer>
                <p className="text-slate-600 dark:text-slate-200 text-right text-small font-light py-1 mr-2">© Powered by <strong>Cogni2 AI</strong></p>
              </footer>
            )}
          </Grid>
        </Grid>
      </div>
      {!login && 
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className="flex lg:hidden fixed z-50 bottom-0 w-full shadow-lg bg-secondary py-1"
        >
          <BottomNavigationAction 
            label={lang('menu.home')} 
            icon={<HomeIcon className="text-white" />} 
            className="border-0 border-r border-solid border-white/30" 
            onClick={() => router.push('/home')} 
            sx={{ color: 'white', '&.Mui-selected': { color: '#FFFFFF' }}}
          />
          <BottomNavigationAction 
            label={lang('menu.inbox')} 
            icon={<InboxIcon className="text-white" />} 
            className="border-0 border-r border-solid border-white/30" 
            onClick={() => router.push('/inbox')} 
            sx={{ color: 'white' }}
          />
          <BottomNavigationAction 
            label={lang('menu.copilot')} 
            icon={<HeadSetIcon className="text-white" />} 
            className="border-0 border-r border-solid border-white/30" 
            onClick={() => router.push('/copilot')} 
            sx={{ color: 'white' }}
          />
          <BottomNavigationAction 
            label={lang('logout')} 
            icon={<LogoutIcon className="text-white" />} 
            onClick={handleLogout} 
            sx={{ color: 'white' }}
          />
        </BottomNavigation>
      }
    </main>
  )
}