import { Avatar, Stack, Typography } from "@mui/material";
import { getUserName } from '@/helpers/utils';
import { useLanguage } from '@/hooks/useLanguage';

type ICardUser = {
  user: any;
  role: string;
  responsiveOnlyThumb?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const CardUser = ({user, role, responsiveOnlyThumb = false, size = "medium"}: ICardUser) => {
  const { lang } = useLanguage()

  const sizeThumb = {
    small: 'w-8 h-8 text-small',
    medium: 'w-10 h-10 text-xs',
    large: 'w-14 h-14 text-sm',
  }

  return (
    <Stack direction="row" className="items-center gap-2">
      {user?.photo ? 
        <Avatar alt={getUserName(user)} src={user?.photo} /> : 
        <div className="flex flex-row flex-nowrap justify-start items-center">
          <div className={`${sizeThumb[size]} rounded-full font-medium bg-slate-200 dark:bg-slate-500 text-slate-500 dark:text-slate-300 flex items-center justify-center md:mr-2`}>
            {getUserName(user, true) || 'C'}
          </div>
        </div>
      }

      <Stack className={`${responsiveOnlyThumb ? 'hidden md:flex ': ''}items-start`}>
        <Typography className="whitespace-nowrap text-small md:text-xs md:leading-4 text-slate-600 dark:text-slate-300 font-light md:font-medium">
          {getUserName(user) || lang('loading')}
        </Typography>
        <Typography className="truncate text-small text-slate-500 dark:text-slate-300 font-light">
          {role || lang('loading')}
        </Typography>
      </Stack>
    </Stack>
  )
}