"use client";
import * as Sentry from "@sentry/nextjs";

export function reportError(error, errorInfo) {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
}

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0, 
  debug: process.env.NODE_ENV !== "production",
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
});
