import * as React from "react"
import SvgIcon from '@mui/material/SvgIcon';

type IIconProps = {
  className?: string
  width?: number
  height?: number
}

export const DashBoardIcon = ({ className, width, height }: IIconProps, ...props: any) => {
  return (
    <SvgIcon 
      width={width || 20}
      height={height || 20}
      viewBox="0 0 32 32"
      className={className}
      {...props}
    >
      <path d="M3.987 4H3v24h26v-.963l-24.996-.05L3.987 4zM17 5a1 1 0 00-1-1h-3a1 1 0 00-1 1v20h5V5zm-6 6a1 1 0 00-1-1H7a1 1 0 00-1 1v14h5V11zm12 6a1 1 0 00-1-1h-3a1 1 0 00-1 1v8h5v-8zm5 5h-3a1 1 0 00-1 1v2h5v-2a1 1 0 00-1-1z" />
    </SvgIcon>
  )
}
