'use client'
import { useRouter, usePathname, useSearchParams } from 'next/navigation';

export const useRouteName = () => {
  const router = useRouter();
  const routeName = usePathname()
  const searchParams = useSearchParams();

  const removeURLParam = (param: string) => {
    const params = new URLSearchParams(searchParams?.toString());
    params.delete(param);
  };

  const removeURLParamAndRedirect = (param: string, newPath: string) => {
    const params = new URLSearchParams(searchParams?.toString());
    params.delete(param);
  
    router.replace(`${newPath}?${params.toString()}`);
  };

  return { routeName, removeURLParam, removeURLParamAndRedirect };
};