
import { useContext, useState, MouseEvent } from 'react';
import { Search } from '@/components/modules/layouts/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLanguage } from '@/hooks/useLanguage'
import { CopilotContext } from '@/contexts/CopilotContext';
import { Container, Alert, Button, Link, Popover, Paper, Typography, Tooltip } from "@mui/material";
import { useThemeSwitch } from '@/hooks/useThemeSwitch';
import { useRouter } from 'next/navigation';

type INavBarActions = {
  className: string
}

export const NavBarActions = ({className}: INavBarActions) => {
  const [anchorNotification, setAnchorNotification] = useState<HTMLButtonElement | null>(null);
  const { ThemeSwitch } = useThemeSwitch()
  const { sessions } = useContext(CopilotContext)
  const { FormattedLang } = useLanguage()
  const router = useRouter()

  const handleCloseNotification = () => setAnchorNotification(null)

  const handleOpenNotification = (event: MouseEvent<HTMLButtonElement>) => {
    if(anchorNotification){
      setAnchorNotification(null);
    }else{
      setAnchorNotification(event?.currentTarget);
    }
  }

  const handleSessionNavigate = (sessionId: string) => {
    router.push(`/copilot#${sessionId}`)
  }

  const openNotification: boolean = Boolean(anchorNotification);
  const idNotification: string = openNotification ? 'notification-popover' : '';

  return (
    <Container className={`${className} relative flex-1 h-full items-stretch justify-end pr-3`}>
      <div className="flex items-center pr-5 mr-2 border-0 border-solid border-r border-white dark:border-slate-800">
        <Search />
      </div>
      <ThemeSwitch />
      {/* // TODO: Botoes de Ajuda e Config */}
      {/* <Tooltip title="Precisa de ajuda?" arrow>
        <Button className="no-dialog min-w-auto normal-case flex-1">
          <Link 
            target="_blank"
            href={`https://wa.me/551148633136?text=${encodeURIComponent("Olá, estou precisando de ajudar com ")}`}
            className="text-slate-500 hover:text-secondary hover:scale-110 origin-center transition align-middle"
          >
            <HelpIcon className="text-xl" />
          </Link>
        </Button>
      </Tooltip>
      <Tooltip title="Editar Perfil" arrow>
        <Button 
          type="button"
          onClick={() => router.push("/profile")} 
          className="no-dialog text-slate-500 min-w-auto normal-case flex-1 hover:text-secondary transition hover:scale-110 origin-center"
        >
          <SettingsIcon className="text-xl" />
        </Button>
      </Tooltip> */}
      <Tooltip title="Notificações" arrow>
        <Button 
          type="button"
          onClick={handleOpenNotification} 
          className={`${openNotification ? 'text-secondary scale-110' : 'text-slate-500'} no-dialog min-w-auto normal-case flex-1 hover:text-secondary transition hover:scale-110 origin-center relative`}
        >
          {sessions && sessions.length > 0 ? 
          <span className="bg-red-700 text-white absolute right-0 top-0 w-4 h-4 flex items-center justify-center rounded-full text-small font-normal text-center shadow-inner">
            {sessions.length}
          </span>
          : ""}
          <NotificationsIcon className="text-xl" />
        </Button>
      </Tooltip>
      <Popover
        id={idNotification}
        open={openNotification}
        anchorEl={anchorNotification}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -75,
        }}
        className="max-w-full w-full"
      >
        <Paper 
          sx={{
            minWidth: 200,
            width: '100%',
            padding: 2,
          }}
          className="dark:text-slate-200 dark:bg-slate-900 max-w-56"
        >
          <Typography variant="h4" className="text-xs uppercase text-slate-500 font-semibold mb-1">
            <FormattedLang id="opened.sessions" />
          </Typography>
          <div>
            {sessions && sessions.length > 0 ? sessions.slice(0,8).map((session, index: number) => {
              const ticketId = session?.ticket_number?.split('_')[2];
              return (
                <div key={session.id} className="flex items-center justify-start gap-1 pl-1 text-left border-0 border-solid border-b border-gray-200 dark:border-slate-800 last:border-0">
                  <span className="text-xl text-successDarker font-bold align-middle">{index + 1}</span>
                  <Button
                    type="button"
                    onClick={() => handleSessionNavigate(session.id)}
                    className="no-dialog flex flex-col justify-start items-start text-small min-w-auto normal-case text-successDarker w-full"
                  >
                    <div className="text-left">{`${session.title} #${ticketId}`}</div>
                    {session?.customer?.name && 
                      <div className="text-left"><FormattedLang id="customer" />: {session?.customer?.name}</div>
                    }
                  </Button>
                </div>
              )
            }) : <Alert className="text-small"><FormattedLang id="no.open.calls" /></Alert>}
          </div>
          <Typography variant="h4" className="text-xs uppercase text-slate-500 font-semibold mb-1 mt-4">
            <FormattedLang id="logs" />
          </Typography>
          <Alert className="text-small"><FormattedLang id="no.recent.activity" /></Alert>
        </Paper>
      </Popover>
    </Container>
  )
}