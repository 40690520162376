import { useTheme } from 'next-themes';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

export const useThemeSwitch = () => {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const ThemeSwitch = () => (
    <Tooltip title="Mudar Tema Claro/Escuro" arrow>
      <Button 
        type="button"
        value={theme} 
        onClick={toggleTheme} className="flex-1 min-w-auto normal-case transition hover:scale-110 origin-center text-slate-500 hover:text-secondary"
      >
        {theme === 'light' ? <LightModeOutlinedIcon className="text-xl" /> : <DarkModeIcon className="text-xl" />}
      </Button>
    </Tooltip>
  )

  return { ThemeSwitch }
};