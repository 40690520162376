import * as React from "react"
import SvgIcon from '@mui/material/SvgIcon';

type IIconProps = {
  className?: string
  width?: number
  height?: number
  color?: string
}

export const DatabaseIcon = ({ className, width, height, color }: IIconProps, ...props: any) => (
    <SvgIcon 
        viewBox="0 0 16 16" 
        width={width || 18}
        height={height || 17}
        className={className}
        {...props}
    >
        <title>645</title>
        <defs></defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(1.000000, 0.000000)" fill={color || "#000000"}>
                <ellipse cx="6.43" cy="2.421" rx="6.43" ry="2.421" className="si-glyph-fill"></ellipse>
                <path d="M6.463,11.08 C2.947,11.08 0.016,10.1 0.016,9.444 L0.016,12.713 C0.016,13.901 2.903,14.859 6.463,14.859 C10.023,14.859 12.91,13.9 12.91,12.713 L12.91,9.444 C12.91,10.1 9.979,11.08 6.463,11.08 L6.463,11.08 Z" className="si-glyph-fill"></path>
                <path d="M6.494,6.051 C2.978,6.051 0.047,5.049 0.047,4.377 L0.047,7.723 C0.047,8.937 2.934,9.919 6.494,9.919 C10.054,9.919 12.941,8.937 12.941,7.723 L12.941,4.377 C12.941,5.049 10.01,6.051 6.494,6.051 L6.494,6.051 Z" className="si-glyph-fill"></path>
            </g>
        </g>
  </SvgIcon>
)
