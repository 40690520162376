import * as React from "react"
import SvgIcon from '@mui/material/SvgIcon';

type IIconProps = {
  className?: string
  width?: number
  height?: number
}

export const TicketIcon = ({ className, width, height }: IIconProps, ...props: any) => 
  <SvgIcon 
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.137 11.137a.812.812 0 01-.575.238 1.625 1.625 0 100 3.25.812.812 0 01.813.813V19.5a1.625 1.625 0 01-1.625 1.625H3.25A1.625 1.625 0 011.625 19.5v-4.063a.812.812 0 01.813-.812 1.625 1.625 0 100-3.25.812.812 0 01-.813-.813V6.5A1.625 1.625 0 013.25 4.875h19.5A1.625 1.625 0 0124.375 6.5v4.063a.812.812 0 01-.238.574zM22.75 19.5v-3.356a3.25 3.25 0 010-6.288V6.5h-5.688v2.438h-1.625V6.5H3.25v3.356a3.25 3.25 0 010 6.288V19.5h12.188v-2.438h1.624V19.5h5.688zm-7.313-8.938h1.626v4.876h-1.625v-4.876z"
    />
  </SvgIcon>